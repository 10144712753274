import React from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMutation, useQuery } from "react-query";
import { getExcelTemplate, userBulkUpload } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { useNavigate } from "react-router-dom";

export default function BulkUploadUsers() {
    const navigate = useNavigate()
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [error, setError] = useState<boolean>(false)

    const { data: excelTemplate } = useQuery("template", getExcelTemplate)

    const downloadExcelTemplate = () => {
        const url = `${excelTemplate?.data}`;
        const link = document.createElement('a');
        link.href = url;
        link.download = 'usersInfo.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const { mutateAsync: bulkUploadAPI } = useMutation<any>("bulk-upload", userBulkUpload);

    const handleFileChange = (e: any) => {
        e.preventDefault();
        setSelectedFile(e?.target?.files[0] ?? "")
    };

    const handleUploadSubmit = async (e: any) => {
        e.preventDefault();
        if (selectedFile) {
            let formData: any = new FormData();
            formData.append("document", selectedFile);
            let response = await bulkUploadAPI(formData);
            if (response.status === 1) {
                snackActions.success(response?.message ?? "File uploaded Successfully!");
                setError(false)
                setTimeout(() => {
                    navigate("/users")
                }, 3000)
            } else {
                setError(true)
                snackActions.error(response?.message ?? "Something went wrong!");
            }
        }
    };

    return (
        <>
            <Card>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <CardHeader title="Bulk Upload">
                </CardHeader>
                <Button variant="contained" sx={{ margin: "10px 7px 0 0", height: "35px" }} onClick={() => navigate("/users")}>Back&nbsp;<ArrowBackIcon /></Button>
                </Box>
                <Divider />
                <CardContent sx={{ p: 2 }}>
                    <Box sx={{ mb: 4, border: '1px solid #000', p: 2, backgroundColor: '#f0f0f0' }}>
                        <h5>How to Upload ?</h5>
                        <hr />
                        <ol>
                            <li>Download a template <span onClick={downloadExcelTemplate} style={{ cursor: 'pointer', color: 'blue' }}>Click to download template</span></li>
                            <li>Add your data to the template file, Make sure it's a '.xlsx'</li>
                            <li>Upload file</li>
                        </ol>
                    </Box>
                    <Box sx={{ border: '1px solid #000', p: 1, width: 0.25 }}>
                        <input type="file" id="file" onChange={handleFileChange} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="contained" color="success" size="large" onClick={handleUploadSubmit}>Upload &nbsp;<CloudUploadIcon /></Button>
                    </Box>
                    <Box>
                        <p className="text-danger">{error}</p>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}
