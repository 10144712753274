import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { KTCard } from "../../_metronic/helpers";
import MuiTable from "../../components/table/MuiTable";
import { GridColDef } from "@mui/x-data-grid";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Tooltip } from "@mui/material";
import { getSupport } from "../../api";
import { useMutation } from "react-query";
import { PageTitle } from "../../_metronic/layout/core";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { charValidate } from "../../components/utility";
// import { getMostSearchedLocation, getUsers } from "../../api";

const Support: React.FC = () => {
    const tableRef = useRef();
    const userType: null | string = localStorage.getItem("userType")
        ? localStorage.getItem("userType")
        : null;


    const [tableOptions, setTableOptions] = useState({
        offset: 0,
        limit: 10,
        hasNextPage: true,
        hasPrevPage: true,
        totalRowCount: undefined,
        search: "",
    });
    const [selectedRow, setSelectedRow] = useState<any>();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const {
        mutateAsync: assignmentAPI,
        data: assignmentData,
        isLoading: isLoadingAssignment,
    } = useMutation(getSupport);

    useEffect(() => {
        getAssignments({ offset: 0, limit: 10 });
    }, []);

    const getAssignments = async (query: | {
        offset: number,
        limit: number,
    } | undefined,) => {
        let response = await assignmentAPI(query);
        if (response?.status) {
            setTableOptions((prevState: any) => {
                return {
                    ...prevState,
                    offset: response?.data?.offset,
                    limit: response?.data?.limit,
                    hasNextPage: response?.data?.hasNextPage,
                    hasPrevPage: response?.data?.hasPrevPage,
                    totalRowCount: response?.data?.totalDocs,
                };
            });
        }
    };

    const columns: GridColDef[] = [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            sortable: false,
            minWidth: 150,
            renderCell: (data: any) => data?.row?.title ?? "N/A",
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            sortable: false,
            minWidth: 150,
            renderCell: (data: any) => charValidate(data?.row?.description, 30) ?? "N/A",
        },
        {
            field: "userId",
            headerName: "User",
            flex: 1,
            sortable: false,
            minWidth: 150,
            renderCell: (data: any) => `${data?.row?.userId?.firstName} ${data?.row?.userId?.lastName}` ?? "N/A",
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            sortable: false,
            headerAlign: "center",
            minWidth: 200,
            align: "center",
            renderCell: (data) => (
                <Box display="flex" alignItems="center">
                    {userType?.includes('superAdmin') &&
                        <>
                            <Tooltip title="View">
                                <VisibilityRoundedIcon
                                    onClick={() => {
                                        setSelectedRow(data.row);
                                        setDialogOpen(true)
                                    }}
                                    sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
                                    color="info"
                                />
                            </Tooltip>
                        </>}
                </Box>
            ),
        },
    ];
    return (
        <>
            <Card className="p-6">
                <>
                    <PageTitle>Support</PageTitle>
                    <KTCard>
                        <>
                            <MuiTable
                                columns={columns}
                                data={assignmentData?.data?.docs ?? []}
                                loading={isLoadingAssignment}
                                isRowClickable={true}
                                isAddEnable={false}
                                tableRef={tableRef}
                                tableOptions={tableOptions}
                            />
                        </>
                    </KTCard>
                </>
            </Card>
            <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Box className="modal-header">
                        <h2>Support Information</h2>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box className="fv-row mb-5">
                        <label className="form-label fw-bold">Title</label>
                        <TextField
                            value={selectedRow?.title ?? ""}
                            fullWidth
                            disabled
                            sx={{ mb: 1 }}
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                    </Box>
                    <Box className="fv-row mb-5">
                        <label className="form-label fw-bold">Description</label>
                        <TextField
                            value={selectedRow?.description ?? ""}
                            fullWidth
                            disabled
                            multiline
                            rows={4}
                            sx={{ mb: 1 }}
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleDialogClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Support;
