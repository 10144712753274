import React, { useEffect } from "react";
import { KTCard } from "../../_metronic/helpers";
import { PageTitle } from "../../_metronic/layout/core";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, CardActions, CardContent, Divider, TextField, Tooltip, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { useMutation } from "react-query";
import { getUserDetailsById } from "../../api";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { charValidate, handleZipDownload } from "../../components/utility";
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";

const UserDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedRow }: any = location?.state;
    const {
        mutateAsync: getUsersDataById,
        data: usersDataByid,
    } = useMutation("user-list", getUserDetailsById);

    useEffect(() => {
        if (selectedRow) {
            getUsersDataById({ id: selectedRow?._id })
        }
    }, [selectedRow])
    const evidenceData = usersDataByid?.data?.Evidence;
    return (
        <>
            <Card className="p-6">
                <PageTitle>User Details</PageTitle>
                <KTCard>
                    <Button variant="contained" sx={{ marginLeft: "auto", height: "35px", width: 0.06 }} onClick={() => navigate("/users")}>Back&nbsp;<ArrowBackIcon /></Button>
                    <Box className="fv-row mb-5">
                        <label className="form-label fw-bold">First Name</label>
                        <TextField
                            value={selectedRow?.firstName}
                            fullWidth
                            disabled
                            sx={{ mb: 1 }}
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                    </Box>
                    <Box className="fv-row mb-5">
                        <label className="form-label fw-bold">Last Name</label>
                        <TextField
                            value={selectedRow?.lastName}
                            fullWidth
                            disabled
                            sx={{ mb: 1 }}
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                    </Box>
                    <Box className="fv-row mb-5">
                        <label className="form-label fw-bold">Email</label>
                        <TextField
                            value={selectedRow?.email}
                            fullWidth
                            disabled
                            sx={{ mb: 1 }}
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                    </Box>
                    <Box className="fv-row mb-5">
                        <label className="form-label fw-bold">Mobile Number</label>
                        <PhoneInput
                            value={`${selectedRow?.countryCode}-${selectedRow?.mobileNumber}`}
                            inputProps={{
                                name: "mobileNumber",
                            }}
                            inputStyle={{
                                background: "#f9f9f9",
                                border: "none",
                            }}
                            inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-100"
                            disabled
                            disableDropdown
                        />
                    </Box>

                    {selectedRow?.collegeCourse.length > 0 &&
                        <Typography sx={{ fontSize: 20 }} gutterBottom>
                            College Information:
                        </Typography>}
                    <Grid container spacing={2}>
                        {selectedRow?.collegeCourse?.map((items: any, key: any) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={key?._id}>
                                    <Box sx={{
                                        border: '1px solid #ccc',
                                        backgroundColor: '#f5f5f5',
                                        padding: '2px',
                                        borderRadius: '8px'
                                    }}>
                                        <Box key={key?._id}>
                                            <CardContent>
                                                {items?.studentID && <>
                                                    <Divider sx={{ mb: 1 }} />
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                        StudentID : <Typography component="span" color="text.secondary"> {(items?.studentID)}</Typography>
                                                    </Typography>
                                                </>}
                                                {items?.batchId?.name && <><Divider sx={{ mb: 1 }} />
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                        Batch : <Typography component="span" color="text.secondary"> {(items?.batchId?.name)}</Typography>
                                                    </Typography>
                                                </>}

                                                {items?.collegeId?.name && <><Divider sx={{ mb: 1 }} />
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                        College : <Typography component="span" color="text.secondary"> {(items?.collegeId?.name)}</Typography>
                                                    </Typography>
                                                </>}
                                                {items?.courseId?.name && <><Divider sx={{ mb: 1 }} />
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                        Course : <Typography component="span" color="text.secondary"> {(items?.courseId?.name)}</Typography>
                                                    </Typography>
                                                </>}

                                                {items?.startDate && <><Divider sx={{ mb: 1 }} />
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                        Start Date : <Typography component="span" color="text.secondary">  {dayjs(items?.startDate).format("DD/MM/YYYY")} </Typography>
                                                    </Typography>
                                                </>}
                                                {items?.studentID && <> <Divider sx={{ mb: 1 }} />
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                        Finish Date : <Typography component="span" color="text.secondary"> {dayjs(items?.endDate).format("DD/MM/YYYY")} </Typography>
                                                    </Typography>
                                                </>}
                                            </CardContent>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    <Typography sx={{ fontSize: 20 }} gutterBottom>
                        Evidence Details :
                    </Typography>
                    {evidenceData?.length > 0 ?
                        <Box sx={{ display: "flex" }}>
                            {evidenceData?.map((items: any, key: any) => {
                                let evidenceDescription: string = items?.desc;
                                return (
                                    <>
                                        <Box key={key?._id} sx={{
                                            width: '50%',
                                            marginRight: '15px',
                                            border: '1px solid #ccc',
                                            backgroundColor: '#f5f5f5',
                                            padding: '2px',
                                            borderRadius: '8px'
                                        }}>
                                            <CardContent>
                                                <Typography variant="h6" sx={{ mb: 1.5 }} component="div">
                                                    Type : <Typography component="span" color="text.secondary">{items?.type}</Typography>
                                                </Typography>
                                                <Typography sx={{ mb: 1.5 }} variant="h6" component="div">
                                                    Status :
                                                    <Typography
                                                        component="span"
                                                        color={items?.status === 'denied' ? '#D21F3C' : (items?.status === 'approved' ? 'green' : 'text.secondary')}
                                                    >
                                                        &nbsp;{(items?.status.toUpperCase())}
                                                    </Typography>
                                                </Typography>
                                                {/* <Typography variant="h6" component="div">
                                                    Description : <Typography component="span" color="text.secondary"><CustomTooltip title={evidenceDescription}>{charValidate(evidenceDescription, 40)}</CustomTooltip></Typography>
                                                </Typography> */}
                                                <Typography variant="h6" component="div" sx={{cursor: "pointer"}}>
                                                    Description:
                                                    <Typography component="span" color="text.secondary">
                                                        <CustomTooltip title={evidenceDescription}>
                                                            <span>{charValidate(evidenceDescription, 40)}</span>
                                                        </CustomTooltip>
                                                    </Typography>
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" onClick={() => handleZipDownload(items?.documents)}><DownloadRoundedIcon /> &nbsp;Download Evidence</Button>
                                            </CardActions>
                                        </Box>
                                    </>
                                )
                            })}
                        </Box>
                        : <Typography variant="body1" textAlign={"center"} color={"#D21F3C"}>No evidence available !!</Typography>}
                </KTCard>
            </Card>
        </>
    );
};

export default UserDetails;
